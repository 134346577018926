import React from "react";

class Button extends React.Component {


  render() {
    return (
      <div>
        <button className="ui primary button" disabled={this.props.disabled} onClick={this.props.onClick}>{this.props.label}</button>
      </div>
    );
  }

  // componentDidUpdate() {
  //   // console.log('componentDidUpdate');
  // }

}


Button.defaultProps = {
  label: "Click here"
}
export default Button;