import hmeImg from '../img/house1.jpg';
import React from 'react';
import '../css/bootstrap-4.3.1.css'

export const HeaderImg = ({title, subTitle}) => {
    return (
        <section>
            <div style={{ backgroundImage: `linear-gradient(to bottom right, rgba(18, 187, 173, 0.5) 0%, rgba(79, 112, 206, 0.5) 100%), url(${hmeImg})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
                }}>

                <div className="container" style={{minHeight: '260px'}}>
                    <div className="text-center justify-content-center align-self-center">
                        <h1 className="pt-5 pb-6" style={{color:'white'}}>{title}</h1>
                        <h2 style={{color:'white'}}>{subTitle}</h2>
                    </div>
                </div>
            </div>
        </section>
    )
}