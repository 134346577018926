import React from "react";
class Field extends React.Component {
  render() {
    return (
      <div>
        <input
          id={this.props.id}
          className={this.props.inputClassName}
          placeholder={this.props.placeholder}
          defaultValue={this.props.value}
          type={this.props.type}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

Field.defaultProps = {
  inputClassName: "ui input",
  type: "text",
  defaultValue: ""}

export default Field;