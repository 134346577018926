import axios from 'axios';

const getSociallyDisadvantaged = async (addressString) => {
    try {
        // console.log(`addressString: ${addressString}`);
        let config = {
            method: 'post',
            url: 'https://camrp-batch.azurewebsites.net/socially_disadvantaged',
            headers: {
                'x-auth': 'dlfjsjdfhsdhf34rsdjhfsdjf894t79347t3hfkjgjdsfhsdfhq0weewt049t879eyfisdjkgjfdglk',
                'Content-Type': 'application/json'
            },
            data: { addressString }
        };
        // console.log(config);
        const resp = await axios(config);
        return (resp.data)
    } catch (err) {
        return (err)
    }
}



export default getSociallyDisadvantaged


