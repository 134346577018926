import "./styles.css";
import React from "react";
import Field from "./components/field";
import Button from "./components/button";
import getSociallyDisadvantaged from "./components/locationinfo";
import Container from "./components/container";
import SDResults from "./components/sdresults";
import './partials/HeaderImg';
import {HeaderImg} from "./partials/HeaderImg";
import * as XLSX from 'xlsx'
import Input from "./components/input";


class App extends React.Component {

  // not required but specially named for JS
  state = {
    SDResultsObj: {},
    HTI: 0,
    status: "INIT",
    sociallyDisadvantagedResults: null,
    lat: null,
    SDResultsObjList: null
  }; // defaults, same as below. Babel actually creates construction code.
  // constructor not needed, above is fine:
  // constructor(props) {
  //   // called first any time it's created
  //   super(props); // base class has constructor of its own. We don't want to override this, so we want to use the parent's constructor. Super is a ref to the parent's constructor function.

  //   // init the state obj, this is the ONLY time we do direct assignment to it.
  //   // this.state = { sociallyDisadvantagedResults: null, lat:null }; // defaults


  // }

  componentDidMount() {
    // window.navigator.geolocation.getCurrentPosition(
    //   position => {
    //     this.setState({lat: position.coords.latitude}); // this will re-render the App component when setState is updated.
    //   },
    //   err => console.log(err)
    // )
  }

  // must define render
  render() {
    // this is called VERY frequently. Never do inits/requests in render method as it's called all the time (!)
    // move inits to constructor.

    var handleClick = async (ev) => {
      console.log(this);
      var addressString = document.getElementById("address").value;
      var hhsizeString = document.getElementById("hhSize").value;
      var hhincomeString = document.getElementById("hhIncome").value;
      // Remove commas from hhincomeString and parse it as a number
      var hhincome = parseFloat(hhincomeString.replace(/,/g, ''));
      if (addressString === "") {
        return (alert("Please enter an address!"));
      }
      if (hhsizeString === "") {
        return (alert("Please enter a household size!"));
      }
      if (isNaN(hhincome)) {
        return (alert("Please enter an income!"));
      }
      this.setState({ status: "LOADING" });
      var SDResultsObj = await getSociallyDisadvantaged(addressString);
      SDResultsObj.HHSize = await hhsizeString;
      SDResultsObj.HHIncome = await hhincome;
      console.log(SDResultsObj);
      this.setState({ status: "COMPLETE1", SDResultsObj});
      

    }


    const readExcel = async (file) => {
        const excelFile = file.target.files[0];
        const data = await excelFile.arrayBuffer();
        let addresses = [];
        const wb = XLSX.read(data);
        const ws = wb.Sheets[wb.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(ws);

        addresses = jsonData;

        console.log(addresses)
        let SDResultsObjList = []

        for (let i= 0; i < addresses.length; i++){
          this.setState({ status: "LOADING" });
          console.log(addresses[i].Address);
          var SDResultsObj = await getSociallyDisadvantaged(addresses[i].Address);
          SDResultsObj.HHSize = await addresses[i].Household_Size;
          SDResultsObj.HHIncome = await addresses[i].Household_Income;
          console.log(SDResultsObj);
          SDResultsObjList.push(SDResultsObj)
        }
        this.setState({ status: "COMPLETE2", SDResultsObj: null, SDResultsObjList: SDResultsObjList});

    }
    // console.log(this.state);
    return (
      <Container>
        <div id="home-page">
          <HeaderImg title="California Mortgage Relief Program" subTitle="Location Tools"/>
            <section>
            </section>
        </div>
        <hr />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            }}
        >
        <h2>Socially Disadvantaged and AMI Lookup</h2>
        <p>Enter fields below to determine if it is in a CA MRP Socially Disadvantaged area and calculate their Area Median Income (AMI).</p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            }}
        >
        <label>Full Address</label>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            }}
        >
        <Field id="address" placeholder="123 Main St, Sacramento, 95815" />
        <br></br>
        <br></br>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            }}
        >
        <label>Household Size</label>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            }}
        >
        <Field id="hhSize" placeholder="Please enter household size, e.g. '2'" />
        <br></br>
        <br></br>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            }}
        >
        <label>Household Income</label>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            }}
        >
        <Field id="hhIncome" placeholder="Please enter income, e.g. '65000'" />
        <br></br>
        <br></br>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            }}
        >
        <Button label="Calculate" disabled={(this.state.status === "LOADING")} onClick={handleClick} />
        </div> 
        <br></br>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            }}
        >
        <p>Or upload a file to get multiple results. Click <a href="https://camrp.blob.core.windows.net/misc-storage/CaLocation_Template.xlsx">Here </a>
         to download excel template.</p>
        <Input label="Upload File" onChange={(e) => {
            readExcel(e);
          }}
          />
        <SDResults status={this.state.status} SDResultsObj={this.state.SDResultsObj} SDResultsObjList={this.state.SDResultsObjList}>
          </SDResults>
        <hr />
        </div>
        </Container>
    );
  }

}
// -- as a function (cannot access state unless thru hooks (later on))
// function App2() {
//   return (
//     <Container>
//       <h1 className="ui header">CA MRP Location & Calculation Tools (BETA)</h1>
//       <hr/>
//       <h4 className="ui header">Socially Disadvantaged</h4>

//       <p>Enter an address to determine if it is in a CA MRP Socially Disadvantaged area.</p>
//      <Field id={"address"} inputClassName="ui input" labelClassName="ui right pointing label" placeholder={"123 Main St, Sacramento, 95815"} label={"Address"}/>
//      <Button label={"Calculate"} onClick={handleClick} />
//     </Container>
//   );
// }


export default App
